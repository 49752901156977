// TODO: Break these functions out into separate files.

function aliasesFormatted(modifier) {
  if (typeof modifier.aliases === "undefined") {
    return null
  }
  if (modifier.aliases.length === 0 && modifier.aliasActive) {
    return null
  }
  if (!modifier.aliasActive) {
    return null
  }
  const aliasNames = modifier.aliases.map((alias) => alias.name)
  return `${arrayToString(aliasNames)}`
}

function arrayToString(array) {
  if (array.length) {
    let resultString = ""
    array.forEach(function (keyword, i) {
      resultString += keyword
      if (i < array.length - 1) {
        resultString += ", "
      }
    })
    return resultString
  } else {
    return ""
  }
}

function ensureNumberDiceOrNull(input) {
  if (Number(input)) {
    return Number(input)
  }
  if (typeof input === "string") {
    return formatDiceNotation(input)
  } else {
    // Fallback to catch null values.
    return input
  }
}

function ensureNumberOrNull(input) {
  // Convert the input to either a number or null.
  if (input === null) {
    return null
  }
  const inputStringNoWhitespace = removeAllWhitespace(String(input))
  if (inputStringNoWhitespace === "") {
    return null
  }
  if (Number(inputStringNoWhitespace)) {
    return Number(inputStringNoWhitespace)
  }
  return inputStringNoWhitespace
}

function formatDiceNotation(input) {
  // Remove whitespace and convert to uppercase.
  const trimmedInput = removeAllWhitespace(input).toUpperCase()
  // Try to split string at "+".
  const subStrArray = trimmedInput.split("+")
  // Return trimmed string if there was no "+".
  if (subStrArray.length < 2) {
    if (trimmedInput === "USER") {
      return "User"
    }
    return trimmedInput
  }
  // Check if value before "+" is a number.
  if (isNaN(Number(subStrArray[1]))) {
    // Value after "+" is not a number.
    // E.g. "3+D3"
    // Add the "+" back in but swap the values either side of the operator.
    // E.g. "D3+3"
    return `${subStrArray[1]}+${subStrArray[0]}`
  }
  return trimmedInput
}

function formatThousandsSeparator(num, locale = "en-US") {
  return new Intl.NumberFormat(locale, { style: "decimal" }).format(num)
}

function incrementMapResult(result, map) {
  if (map.has(result)) {
    map.set(result, map.get(result) + 1)
  } else {
    map.set(result, 1)
  }
}

function isStringPositiveInteger(input) {
  // Credit: https://stackoverflow.com/a/10834843
  const n = Math.floor(Number(input))
  return n !== Infinity && String(n) === input && n >= 0
}

function numToPaddedString(num) {
  // Converts numbers to padded strings for easier sorting.
  // E.g.
  // 2 -> "02"
  // 10 -> "10"
  // 0 -> "0"
  if (num === 0) {
    // Don't pad zeros.
    return "0"
  }
  return String(num).padStart(3, "0")
}

function processApValue(input) {
  // Ensures that the saved AP value is negative or 0,
  // even if the user entered a positive number.
  if (input > 0) {
    return 0 - input
  }
  return input
}

function removeAllWhitespace(input) {
  // Remove all whitespace from a given string.
  return input.replaceAll(/\s/g, "")
}

function sanitiseClassName(input) {
  // Returns a string that is suitable for use as an HTML class name.
  // Dialed in for weapon & modifier names.

  if (input === null || input === undefined) {
    return ""
  }

  let className = input.toLowerCase()

  const delimiter = "_"
  const charsToReplaceWithDelimiter = ":= ".split("")
  charsToReplaceWithDelimiter.forEach((char) => {
    className = className.replaceAll(char, delimiter)
  })

  className = className.replaceAll(`${delimiter}${delimiter}`, delimiter)
  className = className.replaceAll(`${delimiter}${delimiter}`, delimiter)
  className = className.replaceAll(`${delimiter}-${delimiter}`, "-")
  className = className.replaceAll("*", "asterisk")
  className = className.replaceAll("+", "plus")
  className = className.replaceAll("!", `not${delimiter}`)

  // Remove anything that isn't a valid class name character.
  // Keeps a-z, A-Z, 0-9, underscores & hyphens.
  const regex = /[^a-zA-Z0-9_-]/g
  className = className.replaceAll(regex, "")
  // className cannot start with a digit.
  if (!isNaN(Number(className.substr(0, 1)))) {
    className = `n-${className}`
  }
  // className cannot start with a hyphen followed by a digit.
  if (className.substr(0, 1) === "-") {
    if (!isNaN(Number(className.substr(1, 1)))) {
      className = `n${className}`
    }
  }
  // className must contain at least 1 character.
  if (className === "") {
    className = "foobar"
  }
  return className
}

function sortResultsArray(resultsArray, sortOrder = "asc") {
  // Convert the result number in each nested array to a padded string
  // (adds a leading zero where needed).
  // This is to ensure that numbers 1-9 always appear before 10.
  resultsArray.forEach((item) => {
    item[0] = numToPaddedString(item[0])
  })
  // Sort the array.
  if (sortOrder === "desc") {
    // Sort descending.
    resultsArray.sort((a, b) => b[0] - a[0])
  } else {
    // Sort ascending.
    resultsArray.sort()
  }
  // Now convert the padded strings back into numbers to remove any leading zeros.
  resultsArray.forEach((item) => {
    item[0] = Number(item[0])
  })
  return resultsArray
}

function timestamp() {
  // Timestamp in seconds
  // Credit: https://stackoverflow.com/a/221297/464066
  return Math.floor(Date.now() / 1000)
}

export {
  aliasesFormatted,
  arrayToString,
  ensureNumberDiceOrNull,
  ensureNumberOrNull,
  formatDiceNotation,
  formatThousandsSeparator,
  incrementMapResult,
  isStringPositiveInteger,
  numToPaddedString,
  processApValue,
  removeAllWhitespace,
  sanitiseClassName,
  sortResultsArray,
  timestamp,
}
